// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
}

body {
  min-height: 100vh;
}

#app {
  min-height: 100vh;
}

.footer {
  background-color: $dark-bkg;
  color: $light-txt;
  padding: 30px 0;
  bottom:0;
  height: $footer-height;
  width: 100%;
  position: relative;

}

.script-list {
  padding: 0;
}

.script-entry {
  list-style: none;
  width: 100%;
  text-align: center;
  margin: 0 0 2px 0;
}


